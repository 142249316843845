<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <section>
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/3 vx-dl-sm">
        <vx-data-list ref="vxDataList" pagination search :sst="true" :data="list" :columns="columns" @filter="filter" />
      </div>
      <div class="vx-col w-full md:w-1/3">
        <vx-card class="mb-base" title="active">
          <template slot="title">
            <h6 class="font-semibold">Improve your capture management</h6>
          </template>
          <div class="text-sm">
            <p class="mb-5">Templates are useful for quicker rendering allowing you to switch the screen size captured in one-click.</p>
            <p>It also helps you organise your captures if you wish to monitor several viewports or device screen size.</p>
          </div>
        </vx-card>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Paging from '@/entity/Paging';
export default {
  data() {
    return {
      columns: [
        {
          field: 'name',
          label: 'Name'
        },
        {
          label: 'Full Page',
          renderer: tr => {
            return tr.captureEntireHeight ? 'Yes' : 'No';
          }
        },
        {
          label: 'Viewport Size',
          classes: 'text-xs truncate max-w-48',
          renderer: tr => {
            try {
              const v = tr.viewPorts;
              let label = [];
              for (const item of v) {
                label.push(`${item.width}x${item.height}`);
              }
              return label.length > 0 ? label.join(' | ') : 'N/a';
            } catch (e) {
              return '';
            }
          }
        },
        {
          field: 'fileFormat',
          label: 'Image Format',
          renderer: 'VxDataRendererBadge'
        },
        {
          label: 'Action',
          actions: [
            {
              icon: 'EditIcon',
              handler: tr => {
                this.$vxSlideIn.open({
                  data: tr,
                  config: {
                    component: 'TemplateForm',
                    title: 'Edit Template'
                  }
                });
              }
            },
            {
              icon: 'Trash2Icon',
              handler: tr => {
                this.$vs.dialog({
                  type: 'confirm',
                  color: 'danger',
                  title: 'Confirm Delete',
                  text: 'Are you sure? Deleting this template can deactivate your automation that are using it.',
                  accept: () => {
                    this.deleteItem(tr);
                  },
                  acceptText: 'Yes, delete it!'
                });
              }
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState('templates', ['list'])
  },
  methods: {
    async fetch(params = {}) {
      const { page, size } = new Paging();
      await this.$store.dispatch('templates/list', {
        params: {
          page,
          size,
          ...params
        }
      });
    },
    filter(params) {
      this.fetch(params);
    },
    deleteItem(tr) {
      this.$store.dispatch('templates/delete', tr.id);
    }
  },
  async created() {
    await this.fetch();
  },
  mounted() {}
};
</script>
